import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Snatch Grip Deadlifts 4×6\\@110% 1RM Snatch`}</p>
    <p>{`Hollow Rocks 4x:30`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 AMRAP of:`}</p>
    <p>{`400M Row`}</p>
    <p>{`20-Box Jumps (24/20)`}</p>
    <p>{`10-Deadlifts (225/155)(RX+ 275/185)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The announcement for Open WOD 21.3 will be early today. 3:00pm at
Games.CrossFit.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      